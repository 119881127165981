import Label from './Label';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ValidationError } from './';
import './styles.scss';

const Checkbox = ({
  label,
  labelHidden,
  name,
  onChange,
  required,
  error,
  ...rest
}) => {
  const getClassNames = () =>
    classNames('form__item', 'form__item--checkbox', `form__item--${name}`);

  const handleChange = event => {
    onChange && onChange(event.target.name, event.target.checked);
  };

  return (
    <div className={getClassNames()}>
      <input
        type="checkbox"
        name={name}
        id={name}
        className="form__input"
        onChange={handleChange}
        aria-required={required}
        aria-invalid={error ? 'true' : 'false'}
        {...rest}
      />
      <Label label={label} name={name} required={required} />
      {error && <ValidationError>{error}</ValidationError>}
    </div>
  );
};

Checkbox.defaultProps = {
  labelHidden: false,
  onChange: null,
};

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.node,
};

export default Checkbox;
