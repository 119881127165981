import Label from './Label';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';
import ValidationError from './ValidationError';

const TextInput = ({
  type,
  label,
  labelHidden,
  name,
  onChange,
  required,
  error,
  ...rest
}) => {
  const getClassNames = () =>
    classNames('form__item', `form__item--${type}`, `form__item--${name}`);

  const handleChange = event => {
    onChange && onChange(event.target.name, event.target.value);
  };

  return (
    <div className={getClassNames()}>
      <Label
        label={label}
        name={name}
        labelHidden={labelHidden}
        required={required}
      />
      <input
        type={type}
        name={name}
        id={name}
        className={classNames('form__input', error && 'form__input--error')}
        onChange={handleChange}
        aria-required={required}
        aria-invalid={error ? 'true' : 'false'}
        {...rest}
      />
      {error && <ValidationError>{error}</ValidationError>}
    </div>
  );
};

TextInput.defaultProps = {
  labelHidden: false,
  onChange: null,
  required: false,
};

TextInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.node,
};

export default TextInput;
