import React from 'react';

const Signup = (props) => (
  <svg viewBox="0 0 25 21" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.644775 5C0.644775 3.34315 1.98792 2 3.64478 2H21.6449C23.3017 2 24.6449 3.34315 24.6449 5V5.83117C24.6449 5.87234 24.6424 5.91292 24.6375 5.95279C24.5413 7.49835 23.4924 8.83376 21.9936 9.28341L13.7942 11.7432C13.0444 11.9682 12.2451 11.9682 11.4954 11.7432L3.29595 9.28341C3.06788 9.21499 2.85023 9.12607 2.64478 9.01901V19C2.64478 19.5523 3.09249 20 3.64477 20H5.35075C5.90304 20 6.35075 20.4477 6.35075 21C6.35075 21.5523 5.90304 22 5.35075 22H3.64477C1.98792 22 0.644775 20.6569 0.644775 19V5ZM2.64478 5.72015C2.64478 6.47979 3.14305 7.14948 3.87065 7.36776L12.0701 9.82759C12.445 9.94005 12.8446 9.94005 13.2195 9.82759L21.4189 7.36776C22.1465 7.14948 22.6448 6.47979 22.6448 5.72015C22.6448 4.77014 21.8746 4 20.9246 4H4.36493C3.41491 4 2.64478 4.77014 2.64478 5.72015ZM24.6448 17.5C24.6448 17.7745 24.5319 18.0369 24.3327 18.2258L20.6404 21.7258C20.2396 22.1057 19.6067 22.0888 19.2267 21.688C18.8468 21.2871 18.8637 20.6542 19.2645 20.2742L21.1362 18.5H11.6448C11.0925 18.5 10.6448 18.0523 10.6448 17.5C10.6448 16.9477 11.0925 16.5 11.6448 16.5H21.1362L19.2645 14.7258C18.8637 14.3458 18.8468 13.7129 19.2267 13.312C19.6067 12.9112 20.2396 12.8943 20.6404 13.2742L24.3327 16.7742C24.5319 16.9631 24.6448 17.2255 24.6448 17.5Z"
    />
  </svg>
);

export default Signup;
