import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Notice = ({ children, error }) => (
  <p className={classNames('form__notice', error && 'form__notice--error')}>
    {children}
  </p>
);

Notice.propTypes = {
  children: PropTypes.node.isRequired,
  current: PropTypes.bool,
};

export default Notice;
