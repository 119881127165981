import PropTypes from "prop-types"

const Step = ({ children, current }) => {
  return current ? children : null
}

Step.defaultProps = {
  current: false,
}

Step.propTypes = {
  children: PropTypes.node.isRequired,
  current: PropTypes.bool,
}

export default Step
