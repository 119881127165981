import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import './styles.scss';

const SubmitButton = ({ children, type, ...rest }) => (
  <div className="form__item form__item--button">
    <Button type={type} {...rest}>
      {children}
    </Button>
  </div>
);

SubmitButton.defaultProps = {
  type: 'submit',
};

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

export default SubmitButton;
