import Label from './Label';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ValidationError } from './';
import './styles.scss';

const Select = ({
  label,
  labelHidden,
  name,
  placeholder,
  options,
  onChange,
  required,
  error,
  ...rest
}) => {
  const getOptions = () => {
    const selectOptions = [];

    if (placeholder) {
      selectOptions.push(
        <option
          key="placeholder"
          value=""
          className="form__option--placeholder"
        >
          {placeholder}
        </option>
      );
    }

    if (Array.isArray(options)) {
      selectOptions.push(
        options.map((option, i) => <option key={i}>{option}</option>)
      );
    } else if (typeof options === 'object' && options !== null) {
      selectOptions.push(
        Object.keys(options).map((option, i) => (
          <option key={i} value={option}>
            {options[option]}
          </option>
        ))
      );
    }
    return selectOptions;
  };

  const getClassNames = () =>
    classNames('form__item', 'form__item--select', `form__item--${name}`);

  const handleChange = event => {
    onChange && onChange(event.target.name, event.target.value);
  };

  return (
    <div className={getClassNames()}>
      <Label
        label={label}
        name={name}
        labelHidden={labelHidden}
        required={required}
      />
      {/* eslint-disable jsx-a11y/no-onchange */}
      <select
        name={name}
        id={name}
        className="form__select"
        onChange={handleChange}
        aria-required={required}
        aria-invalid={error ? 'true' : 'false'}
        {...rest}
      >
        {getOptions()}
      </select>
      {/* eslint-enable */}
      {error && <ValidationError>{error}</ValidationError>}
    </div>
  );
};

Select.defaultProps = {
  placeholder: null,
  labelHidden: false,
  onChange: null,
  required: false,
};

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelHidden: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.node,
};

export default Select;
