import React from 'react';

const Share = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4079 2.09083C13.7635 1.92797 14.1814 1.98737 14.4776 2.24287L24.298 10.7157C24.5105 10.899 24.6362 11.1632 24.6444 11.4437C24.6525 11.7242 24.5425 11.9952 24.3411 12.1906L14.5206 21.7178C14.2323 21.9975 13.8046 22.0775 13.4347 21.921C13.0647 21.7644 12.8243 21.4017 12.8243 21V16.0701C6.59747 16.3461 3.3869 19.1819 2.58219 21.3482C2.41364 21.802 1.94429 22.0699 1.46788 21.9842C0.991463 21.8986 0.644775 21.4841 0.644775 21C0.644775 15.2325 2.57712 11.8634 5.36135 10.0016C7.71125 8.43021 10.5183 8.04171 12.8243 7.96713V3.00001C12.8243 2.6089 13.0523 2.25368 13.4079 2.09083ZM14.8243 5.18354V8.95183C14.8243 9.50411 14.3766 9.95183 13.8243 9.95183C11.4324 9.95183 8.64918 10.209 6.47309 11.6641C4.90106 12.7153 3.53175 14.4737 2.94677 17.5193C5.18499 15.532 8.84625 14.0482 13.8243 14.0482C14.3766 14.0482 14.8243 14.4959 14.8243 15.0482V18.6366L22.1641 11.5161L14.8243 5.18354Z"
    />
  </svg>
);

export default Share;
