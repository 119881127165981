import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

class Form extends React.Component {
  handleSubmit = event => {
    event.preventDefault()
    const { onSubmit } = this.props
    onSubmit && onSubmit()
  }

  render() {
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        {this.props.children}
      </form>
    )
  }
}

Form.defaultProps = {
  onSubmit: null,
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
}

export default Form
